import React from "react"
import comprehensive from "../../images/icons/why-us/comprehensive.svg"
import accurate from "../../images/icons/why-us/accurate.svg"
import secure from "../../images/icons/why-us/secure.svg"
import flexible from "../../images/icons/why-us/flexible.svg"
import global from "../../images/icons/why-us/global.svg"
import goodCompany from "../../images/icons/why-us/good-company.svg"
import { FormattedMessage } from "react-intl"
import styles from "./why-us.module.css"
import BackgroundSection from "../BackgroundSection/backgroundSection"

const cards = [
  {
    title: <FormattedMessage id="dlaczego-my.card1.title" />,
    desc: <FormattedMessage id="dlaczego-my.card1.desc" />,
    icon: comprehensive,
  },
  {
    title: <FormattedMessage id="dlaczego-my.card2.title" />,
    desc: <FormattedMessage id="dlaczego-my.card2.desc" />,
    icon: accurate,
  },
  {
    title: <FormattedMessage id="dlaczego-my.card3.title" />,
    desc: <FormattedMessage id="dlaczego-my.card3.desc" />,
    icon: secure,
  },
  {
    title: <FormattedMessage id="dlaczego-my.card4.title" />,
    desc: <FormattedMessage id="dlaczego-my.card4.desc" />,
    icon: flexible,
  },
  {
    title: <FormattedMessage id="dlaczego-my.card5.title" />,
    desc: <FormattedMessage id="dlaczego-my.card5.desc" />,
    icon: global,
  },
  {
    title: <FormattedMessage id="dlaczego-my.card6.title" />,
    desc: <FormattedMessage id="dlaczego-my.card6.desc" />,
    icon: goodCompany,
  },
]

const isDesktop = typeof window !== `undefined` && window.innerWidth > 768

const Header = () => (
  <div className="row">
    <div className="col-7">
      <h1 className={styles.heading}>
        <FormattedMessage id="dlaczego-my.title" />
      </h1>
    </div>
  </div>
)

class WhyUs extends React.Component {
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.section_1}>
          {isDesktop ? (
            <BackgroundSection
              filename="banner-bg.png"
              position="right bottom"
              color="transparent"
              size="100% 100%"
            >
              <Header />
            </BackgroundSection>
          ) : (
            <Header />
          )}
        </div>

        <div className={styles.section_2}>
          <div className="row">
            <div className={styles.wrapper}>
              {cards.map(({ title, desc, icon }, index) => (
                <div key={index} className={styles.card}>
                  <div className={styles.cardHeader}>
                    <h4>{title}</h4>
                    <img src={icon} alt="" width={50} height={50} />
                  </div>
                  <p>{desc}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default WhyUs
