import React from "react"
import Layout from "../components/Layout/layout"
import SEO from "../components/Seo/seo"
import Footer from "../components/Footer/footer"
import { GoogleFont, TypographyStyle } from "react-typography"
import typography from "../utils/typography"
import { injectIntl, intlShape } from "react-intl"
import { compose } from "recompose"
import withPageContext from "../pageContext"
import WhyUs from "../components/WhyUs/WhyUs"

class DlaczegoMy extends React.Component {
  render() {
    const { uri, intl } = this.props
    return (
      <Layout location={uri} isStatic={true}>
        <SEO title={intl.formatMessage({ id: "nav.dlaczego-my" })} />
        <TypographyStyle typography={typography} />
        <GoogleFont typography={typography} />
        <WhyUs />
        <Footer />
      </Layout>
    )
  }
}

DlaczegoMy.propTypes = {
  intl: intlShape.isRequired,
}

export default compose(
  withPageContext,
  injectIntl
)(DlaczegoMy)
